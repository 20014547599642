.flexBlock {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-block: 40px;

  a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .staticImg {
    width: 85px;
    height: 85px;
    object-fit: contain;
  }

  h3 {
    font-size: clamp(18px, 2.2vw, 22px);
    font-weight: 600;
    color: var(--text_color3);
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 50px 0;

    a {
      width: 100%;
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
}

.placesSection {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  margin-bottom: 20px;

  a {
    width: calc(24% - 25px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    border-radius: 8px;
    padding-bottom: 10px;
    transition: 0.3s ease-in-out;
    box-shadow: 0 0 10px 3px var(--box-shadow1);

    &:hover {
      box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
    }
  }

  div {
    width: 100%;
    aspect-ratio: 1.64;
    position: relative;
  }

  h3 {
    font-size: clamp(18px, 2.2vw, 22px);
    font-weight: 600;
    color: var(--text_color3);
    text-align: center;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    a {
      width: 100%;
    }
  }
}

.img {
  width: 100%;
  height: 80%;
}

.homeSlider {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 0 15px;

  h2 {
    font-size: 22px;
    font-weight: 500;
  }

  @media (max-width: 1024px) {
    margin-bottom: 30px;
    padding: 0 16px;
  }
}

.places {
  margin-block: 40px;
}
