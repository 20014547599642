.slidersBlock {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 32px 24px;
  min-height: 422px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
}

.sliderBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(33.33% - 16px);
  height: auto;
  border-radius: 8px;
  transition: 0.3s ease-in-out;
  box-shadow: 0 0 10px 3px var(--box-shadow1);
  overflow: hidden;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
}

.infoBlock {
  display: flex;
  flex-direction: column;
  padding: 15px;

  h3 {
    font-size: 20px;
    font-weight: 700;
    padding-top: 10px;
  }

  h4 {
    font-size: 14px;
    color: var(--text_color2);
    padding: 2px 0 5px;
  }
}

.infoBlock2 {
  display: flex;
  align-items: center;
  gap: 3%;
  padding: 0 15px 15px;

  div {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  span {
    font-size: clamp(12px, 1.2vw, 14px);
    color: var(--text_color2);
  }
}

.flexBlock {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-block: 40px;

  a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  h3 {
    font-size: 23px;
    font-weight: 700;
    color: var(--text_color3);
  }
}