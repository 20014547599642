.posts {
  display: flex;
  justify-content: space-between;
  gap: 2%;

  @media (max-width: 1024px) {
    gap: 16px;

    .info {
      padding: 12px;

      p {
        -webkit-line-clamp: 3;
      }

      span {
        margin-bottom: 8px;
      }
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 50px;

    .postBlock {
      width: 100%;
    }
  }
}

.postBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  width: calc(24%);
  height: max-content;
  transition: 0.3s ease-in-out;
  box-shadow: 0 0 10px 3px var(--box-shadow1);

  &:hover {
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
  }
}

.img {
  width: 100%;
  height: 40%;
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 26px;

  h3 {
    font-size: clamp(17px, 2vw, 25px);
    font-weight: 500;
    color: var(--text_color3);
    padding-bottom: 8px;
  }

  span {
    font-size: clamp(14px, 1.5vw, 19px);
    color: var(--text_color2);
    margin-bottom: 15px;
  }

  p {
    font-size: clamp(14px, 1.5vw, 18px);
    color: var(--text_color3);
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  h4 {
    color: var(--text_color3);
    font-weight: 700;
    font-size: 18px;
    margin-top: 15px;
  }
}
