.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 25px 15px 50px;

  img {
    width: 388px;
    height: 283px;
    float: left;
    margin: 0 10px 10px 0;
  }

  h1 {
    width: 100%;
    font-weight: 500;
    font-size: clamp(21px, 3.5vw, 25px);
  }

  span {
    width: 100%;
    font-size: 14px;
    margin-block: 15px;
  }

  p {
    line-height: 150%;
  }

  @media (max-width: 768px) {
    img {
      float: none;
      width: 100%;
    }
  }
}
