.infoBottom {
  display: flex;
  width: 100%;
  border-top: 1px solid var(--bg_color2);
  margin-top: 25px;
  padding-bottom: 25px;

  .leftSide {
    width: 72%;
    padding-top: 15px;

    @media (max-width: 1200px) {
      width: 100%;
    }

    .dateInfo {
      display: flex;
      align-items: center;
      gap: 15px;

      li {
        display: flex;
        align-items: center;
        gap: 5px;

        span {
          color: var(--text_color2);
          font-size: clamp(12px, 4vw, 16px);
        }

        &:last-of-type {
          position: relative;
          cursor: default;

          &:hover .sharePopover {
            visibility: visible;
            opacity: 1;
          }

          .sharePopover {
            visibility: hidden;
            opacity: 0;
            transition: 0.1s ease-in-out;
            width: max-content;
            position: absolute;
            left: 120%;
            top: 50%;
            display: flex;
            align-items: center;
            gap: 12px;
            background: var(--bg_color1);
            padding: 8px 15px;
            font-size: 20px;
            border-radius: 50px;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
            transform: translateY(-50%);

            div,
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;
              cursor: pointer;

              span {
                color: #ff0000;
                font-weight: bold;
                cursor: default;
              }
            }

            svg {
              width: 100%;
              height: 100%;

              &:hover path {
                fill: #ff0000 !important;
              }
            }

            @media (max-width: 500px) {
              left: -50%;
              top: 100%;
              transform: translateY(20%);
            }
          }
        }
      }
    }

    .brokerBlock {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      margin: 15px 0 45px;

      button {
        height: 36px;
        width: max-content;
        color: #fff;
        background: #037e8c;
        cursor: pointer;
        transition: 0.1s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        border-radius: 5px;
        font-size: 16px;

        &:hover {
          background: var(--text_color1);
          color: var(--text_color6);
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
          transform: scale(1.02);
        }
      }

      .brokerInfo {
        margin-left: auto;
        display: flex;

        .brokerContact {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 10px;
          gap: 5px;

          li {
            display: flex;
            align-items: center;
            gap: 5px;
            color: var(--text_color3);

            &:first-of-type {
              font-weight: 700;
              font-size: 21px;
            }
          }
        }
      }

      @media (max-width: 768px) {
        align-items: flex-start;

        .brokerInfo {
          margin-left: 0;
        }
      }
    }

    .informationTitle {
      font-weight: 700;
      padding-bottom: 9px;
    }

    .informationDescription {
      font-size: 17px;
    }

    .utilityBlock {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      justify-content: space-between;
      margin: 25px 0;
      padding: 19px 0 25px;
      border-top: 1px solid var(--bg_color2);
      border-bottom: 1px solid var(--bg_color2);

      h3 {
        width: 100%;
        font-weight: 700;
        font-size: 20px;
        padding-bottom: 10px;
      }

      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 15px 2%;

        li {
          display: flex;
          align-items: center;
          gap: 8px;
          width: 23.5%;

          span {
            font-size: clamp(15px, 4vw, 18px);
          }
        }
      }

      @media (max-width: 768px) {
        ul li {
          width: 48.5%;
        }
      }
    }
  }
  .streetView {
    h3 {
      margin-bottom: 25px;
      font-weight: 700;
    }

    iframe {
      width: 100%;
      height: 420px;
      border: none;
      outline: none;
      border-radius: 15px;
    }
  }
}
.rightSide {
  position: relative;
  display: flex;
  width: 28%;
  height: 100%;
  padding-left: 40px;
  padding-top: 40px;

  > div {
    top: 80px;
    left: 0;
    height: max-content;
    position: sticky;
  }

  a {
    width: 100%;
  }

  @media (max-width: 1200px) {
    display: none;
  }
}
