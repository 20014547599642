.filtersContainer {
  position: fixed;
  left: 0;
  top: 58px;
  width: 100vw;
  height: 58px;
  display: flex;
  align-items: center;
  background: var(--bg_color5);
  margin-bottom: 15px;
  transition: 0.1s ease-in-out;
  z-index: 10;

  &[class*='hide'] {
    top: 0;
  }

  >div {
    width: 100%;
    height: 100%;
    padding-inline: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .filters {
    width: 100%;
    height: 58px;
    display: flex;
    align-items: center;
    gap: 7px;

    input[type='number'] {

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }
    }

    .submitBtn {
      width: 36px;
      height: 36px;
      transition: 0.1s ease-in-out;

      &:hover .submitBtn1 {
        background: #e8e9ea;

        svg path {
          fill: #3b4144;
        }
      }

      .submitBtn1 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #3b4144;
        border-radius: 5px;
      }

      .submitBtn2 {
        display: none;
        width: max-content;
        background: #037e8c;
        color: #fff;
        padding-inline: 12px;
        height: 32px;
        font-size: 16px;
        font-weight: 500;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.1s ease-in-out;
        border: 1px solid #037e8c;
        margin-top: 10px;
        cursor: pointer;
        user-select: none;

        &:hover {
          background: var(--bg_color1);
          color: #037e8c;
        }
      }

      svg {
        width: 80%;
        height: 80%;

        path {
          transition: 0.1s ease-in-out;
        }
      }
    }

    .button {
      position: relative;
      height: 36px;
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      border: 1px solid #cdd1d4;
      cursor: pointer;
      background: var(--bg_color1);

      &:nth-of-type(6),
      &:nth-of-type(7) {
        width: 150px;
      }

      .filter7 {
        width: 375px !important;
        padding-block: 20px !important;
        gap: 20px;
      }

      &[class*='true'] {

        .menu,
        .minMaxMenu,
        .filter7 {
          visibility: visible;
          opacity: 1;
        }
      }

      @media (hover: hover) {
        &:hover {
          background: var(--bg_color3);

          .menu,
          .minMaxMenu,
          .filter7 {
            visibility: visible;
            opacity: 1;
          }

          >span svg {
            transform: translateY(3px);
          }
        }
      }

      .title,
      .floorTitle {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--text_color1);
        font-weight: 700;
        text-align: center;
        user-select: none;
        padding-inline: 5px;

        span {
          width: 85%;
          font-size: 14px;
          user-select: none;
          display: block;
          align-items: center;
          gap: 3px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          font-weight: 500;

          b {
            max-width: 45%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-align: left;
          }
        }

        svg {
          width: 10%;
          display: block;
          transition: 0.2s ease-in-out;

          path {
            fill: var(--text_color3);
          }
        }
      }

      .menu,
      .filter7 {
        width: max-content;
        min-width: 100%;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 110%;
        left: 0;
        display: flex;
        flex-direction: column;
        background: var(--bg_color1);
        border-radius: 5px;
        border: 1px solid #cdd1d4;
        z-index: 10;
        transition: 0.1s ease-in-out;
        padding-block: 5px;
        max-height: 450px;
        overflow: hidden auto;

        .additionalSubmit {
          width: max-content;
          background: #037e8c;
          color: #fff;
          padding: 8px 12px;
          font-size: 16px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          transition: 0.1s ease-in-out;
          border: 1px solid #037e8c;
          cursor: pointer;
          margin: 30px 0 0 15px;
          user-select: none;

          &:hover {
            background: var(--bg_color1);
            color: #037e8c;
          }
        }

        h3 {
          width: 100%;
          color: var(--text_color3);
          font-weight: 700;
          font-size: 15px;
          text-transform: uppercase;
          user-select: none;
          cursor: default;
        }

        >div {
          padding-inline: 15px;
        }

        label {
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;
          padding-block: 5px;
          padding-inline: 10px 18px;
          transition: 0.1s;
          border-radius: 4px;

          span {
            user-select: none;
          }

          &:hover {
            background: var(--bg_color3);
            transform: scale(1.01);
          }
        }
      }

      .filter7 {
        max-height: max-content;
      }
    }

    .minMaxMenu {
      padding: 10px;
      width: max-content;
      visibility: hidden;
      opacity: 0;
      min-width: 100%;
      position: absolute;
      top: 110%;
      left: 0;
      display: flex;
      flex-direction: column;
      gap: 12px;
      background: var(--bg_color1);
      border-radius: 5px;
      border: 1px solid #cdd1d4;
      z-index: 10;
      transition: 0.1s ease-in-out;
      padding-block: 10px;

      .priceMinMax {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h3 {
          color: var(--text_color3);
          font-weight: 700;
          font-size: 15px;
          text-transform: uppercase;
          user-select: none;
          cursor: default;
        }

        input {
          width: 100%;
          border: 1px solid #cdd1d4;
          outline: none;
          border-radius: 5px;
          color: var(--text_color1);
          height: 36px;
          display: flex;
          align-items: center;
          font-size: 14px;
          padding-inline: 10px;
          color: #3b4144;
          box-shadow: 0 0 3px rgba(16, 24, 40, 0.1);
        }
      }
    }

    input[type='radio'],
    input[type='checkbox'] {
      accent-color: #f7505c;
      pointer-events: none;
      border-radius: 5px;
    }

    @media (max-width: 1200px) {
      position: absolute;
      top: 90%;
      left: 15px;
      flex-direction: column;
      align-items: flex-start;
      z-index: 10;
      background: var(--bg_color7);
      padding: 15px;
      border-radius: 5px;
      width: 380px;

      .submitBtn1 {
        display: none !important;
      }

      .submitBtn2 {
        display: flex !important;
      }

      .button {
        width: 100% !important;
      }
    }

    @media (max-width: 480px) {
      width: calc(100% - 32px);
    }
  }

  .additional,
  .minTotalFloorMax {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 11px;
    width: 100%;

    input[type='text'],
    input[type='number'] {
      width: 100%;
      border: 1px solid #cdd1d4;
      outline: none;
      border-radius: 5px;
      color: var(--text_color1);
      height: 36px;
      display: flex;
      align-items: center;
      font-size: 14px;
      padding-inline: 10px;
      color: #000;
      box-shadow: 0 0 3px rgba(16, 24, 40, 0.1);
    }

    label {
      padding-inline: 0 !important;

      &:hover {
        background: none !important;
        text-shadow: 0 0 1px #7c7c7c;
      }

      span {
        font-size: 14px;
      }
    }
  }

  .darkMode {
    width: max-content;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    border: 1px solid #cdd1d4;
    border-radius: 5px;
    height: 36px;
    padding-inline: 10px;
    transition: 0.1s ease-in-out;

    &:hover {
      color: var(--text_color1);
      background: var(--bg_color3);
      border-color: #e8e9ea;
    }

    img {
      width: 22px;
      height: 20px;
      user-select: none;
      pointer-events: none;
      object-fit: contain;
    }

    span {
      width: max-content;
      user-select: none;
      color: var(--text_color1);
      font-weight: 500;
      font-size: 16px;
    }
  }

  .area {
    .floorBlock {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .floorTitle {
        position: relative;
        height: 36px;
        width: 48%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        border: 1px solid #cdd1d4;
        cursor: pointer;
        background: var(--bg_color1);
        margin-top: 3px;

        &:hover {
          background: var(--bg_color3);

          .menu,
          .filter7 {
            visibility: visible;
            opacity: 1;
          }

          svg {
            transform: translateY(3px);
          }
        }
      }
    }

    .floorMenu1,
    .floorMenu2 {
      position: absolute;
      top: 100%;
      max-height: 240px;
      width: 50%;
      display: flex;
      flex-direction: column;
      background: var(--bg_color1);
      border-radius: 5px;
      border: 1px solid #cdd1d4;
      overflow-y: auto;
      overflow-x: hidden;
      gap: 5px;

      li {
        transition: 0.1s ease-in-out;
        padding: 5px;

        &:hover {
          background: var(--bg_color3);
          transform: scale(1.01);
        }
      }
    }

    .floorMenu1 {
      left: 0;
    }

    .floorMenu2 {
      right: 0;
    }
  }

  .minTotalFloorMax {
    input[type='number'] {
      width: 48%;
    }
  }

  .buildType,
  .status {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 4px 0;

    h3 {
      width: 100%;
    }

    label {
      width: 48%;
      padding-inline: 0 !important;

      &:hover {
        background: none !important;
        text-shadow: 0 0 1px #7c7c7c;
      }
    }
  }

  .filtersBurger {
    display: none;
    align-items: center;
    justify-content: center;
    border: 1px solid #cdd1d4;
    border-radius: 5px;
    width: 90px;
    height: 36px;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    gap: 10px;

    &:hover {
      color: var(--text_color1);
      background: var(--bg_color3);
      border-color: #e8e9ea;
    }

    >span {
      font-size: 16px;
      color: var(--text_color1);
    }

    svg {
      height: 60%;
    }
  }

  @media (max-width: 1200px) {
    .filtersBurger {
      display: flex;
    }

    .filters {
      display: none;
      height: calc(100svh - 58px);
    }

    ul[class*='open'] {
      display: flex;
    }

    .filter7 {
      width: 100%;
      max-height: 240px !important;
      overflow: auto;
    }
  }

  @media (max-width: 480px) {
    .filters .button .filter7 {
      width: calc(100% - 32px) !important;
    }

    .floorMenu1,
    .floorMenu2 {
      top: 100%;
    }
  }
}